import { useEffect, useState, useRef } from "react";
import { useAppContext } from "../../contexts/AppContext";
import DateRangeSelector, { defaultDateRange } from '../../components/filter/DateRangeSelector.js';
import CompanySelector from "../offers/CompanySelector";
import { Heading } from "../../components/catalyst/heading";
import { Divider } from "../../components/catalyst/divider";
import AdCards from "./AdCards";
import Analytics from "./Analytics.js";
import { Radio, RadioGroup, Checkbox, CheckboxGroup } from 'rsuite';
import { mergeAdsDict, useFetchAllCompetitorsAds, useFetchCurrentCompetitorsAds, countPlatforms } from "./util.js";
import ComparativeInsights from "./ComparativeInsights.js";

const AdsPage = () => {
  const { selectedCompetitors } = useAppContext();

  const ACTIVE_FILTER_LIST = [
    { name: 'All', value: 'all' },
    { name: 'Active', value: 'active' },
    { name: 'Inactive', value: 'inactive' },
  ];

  const SORT_LIST = [
    { name: 'Most Recent', value: 'recent' },
    { name: 'Least Recent', value: 'least' },
  ];

  const [PLATFORMS_FILTER_LIST, setPLATFORMS_FILTER_LIST] = useState([
    { displayname: 'All', value: 'all' },
    { displayname: 'Facebook', value: 'facebook' },
    { displayname: 'Instagram', value: 'instagram' },
    { displayname: 'Messenger', value: 'messenger' },
    { displayname: 'Audience Network', value: 'audience_network' },
  ]);

  const ASSET_TYPE_FILTER_LIST = [
    { name: 'All', value: 'all' },
    { name: 'Image', value: 'single_image' },
    { name: 'Video', value: 'video' },
    { name: 'Carousel', value: 'carousel' },
  ];

  const [assetTypesSelected, setAssetTypesSelected] = useState(ASSET_TYPE_FILTER_LIST.map(type => type.value));

  const [COMPANY_FILTER_LIST, setCOMPANY_FILTER_LIST] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  useEffect(() => {
    const updatedCompanyFilterList = [
      { name: 'All', value: 'all' },
      ...selectedCompetitors.map(company => ({
        name: company.getName(),
        value: company.getName(), // Use company name as value
      })),
    ];
    setCOMPANY_FILTER_LIST(updatedCompanyFilterList);
    setCompaniesSelected(updatedCompanyFilterList.map(company => company.value));
  }, [selectedCompetitors]);
  

  const [activeFiltersSelected, setActiveFiltersSelected] = useState(ACTIVE_FILTER_LIST.map(filter => filter.value));
  const [sortSelected, setSortSelected] = useState(SORT_LIST[0].value);
  const [platformsSelected, setPlatformsSelected] = useState(PLATFORMS_FILTER_LIST.map(platform => platform.value));
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const { currentAdsDict, currentAdsLoading, currentAdsError } = useFetchCurrentCompetitorsAds(selectedCompetitors);
  const { allAdsDict, adsLoading, adsError } = useFetchAllCompetitorsAds(selectedCompetitors);

  const [currentAds, setCurrentAds] = useState([]);
  const [allAds, setAllAds] = useState([]);

  useEffect(() => {
    if (selectedCompetitors.length === 0) {
      setCurrentAds([]);
    } else if (currentAdsDict && Object.keys(currentAdsDict).length > 0) {
      setCurrentAds(mergeAdsDict(currentAdsDict));
    }
  }, [selectedCompetitors, currentAdsDict]);

  useEffect(() => {
    if (selectedCompetitors.length === 0) {
      setAllAds([]);
    } else if (allAdsDict && Object.keys(allAdsDict).length > 0) {
      setAllAds(mergeAdsDict(allAdsDict));
    }
  }, [selectedCompetitors, allAdsDict]);

  useEffect(() => {
    if (currentAds.length > 0) {
      const filteredAds = currentAds.filter(ad => {
        const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
        const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
        const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);
        return adStartDate >= startRange && adStartDate <= endRange;
      });
      const counts = countPlatforms(filteredAds);

      const updatedPlatformsList = PLATFORMS_FILTER_LIST.map(platform => ({
        ...platform,
        name: platform.value === 'all'
          ? `All (${filteredAds.length})`
          : `${platform.displayname} (${counts[platform.value] || 0})`
      }));

      setPLATFORMS_FILTER_LIST(updatedPlatformsList);
    } else {
      setPLATFORMS_FILTER_LIST(PLATFORMS_FILTER_LIST.map(platform => ({
        ...platform,
        name: platform.displayname
      })));
    }
  }, [currentAds, dateRange]);

  const handleFilterChange = (checkedValues, currentSelected, setSelected, filterList) => {
    if (currentSelected.includes('all') && checkedValues.includes('all') && checkedValues.length < currentSelected.length) {
      setSelected(checkedValues.filter(value => value !== 'all'));
    } else if (currentSelected.includes('all') && !checkedValues.includes('all')) {
      setSelected([]);
    } else if (checkedValues.length === filterList.length - 1
      || (checkedValues.includes('all') && !currentSelected.includes('all'))
    ) {
      setSelected(filterList.map(filter => filter.value));
    } else {
      setSelected(checkedValues);
    }
  };

  const handleActiveFilterChange = (checkedValues) => {
    handleFilterChange(checkedValues, activeFiltersSelected, setActiveFiltersSelected, ACTIVE_FILTER_LIST);
  };

  const handlePlatformChange = (checkedValues) => {
    handleFilterChange(checkedValues, platformsSelected, setPlatformsSelected, PLATFORMS_FILTER_LIST);
  };

  const handleAssetTypeFilterChange = (checkedValues) => {
    handleFilterChange(checkedValues, assetTypesSelected, setAssetTypesSelected, ASSET_TYPE_FILTER_LIST);
  };

  const handleCompanyFilterChange = (checkedValues) => {
    handleFilterChange(checkedValues, companiesSelected, setCompaniesSelected, COMPANY_FILTER_LIST);
  };

  const hasSelectedCompetitors = selectedCompetitors.length > 0;
  const isDateRangeSelected = dateRange && dateRange.length === 2;

  return (
    <div className="w-full p-6 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      {/* Company Selector */}
      <div className="mb-6">
        <CompanySelector />
      </div>
      {/* Date Range Selector */}
      <div className="mb-6">
        <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} allAds={allAds} />
      </div>

      {/* Prompt to select a competitor if none are selected */}
      {!hasSelectedCompetitors ? (
        <p><strong>Select a competitor to view their ads.</strong></p>
      ) : (
        <>
          {/* Render additional components only if date range is selected */}
          {isDateRangeSelected && (
            <>
              {/* Comparative Insights for multiple competitors */}
              {selectedCompetitors.length > 1 && (
                <ComparativeInsights
                  selectedCompetitors={selectedCompetitors}
                  currentAdsDict={currentAdsDict}
                  allAdsDict={allAdsDict}
                  dateRange={dateRange}
                />
              )}

              {/* Loading and Error States */}
              {currentAdsLoading && <p className="text-sm text-zinc-600">Loading all ads...</p>}
              {currentAdsError && <p>Error: {currentAdsError}</p>}

              {/* Main Content when ads are loaded successfully */}
              {!currentAdsLoading && !currentAdsError && currentAds.length > 0 && (
                <>
                  <Analytics
                    currentAdsDict={currentAdsDict}
                    allAdsDict={allAdsDict}
                    dateRange={dateRange}
                  />
                  <Divider className="my-6" />
                  <Heading className="mb-4">All Ads</Heading>

                  {/* Sort By Section */}
                  <label><strong>Sort By</strong></label>
                  <div className="flex flex-wrap gap-6 items-center mb-4">
                    {/* Sort By */}
                    <div className="flex items-center space-x-2">
                      <RadioGroup
                        name="radio-group-inline"
                        inline
                        value={sortSelected}
                        onChange={setSortSelected}
                      >
                        {SORT_LIST.map((sort) => (
                          <Radio key={sort.value} value={sort.value}>
                            {sort.name}
                          </Radio>
                        ))}
                      </RadioGroup>
                    </div>
                  </div>
                  
                  {/* Company Filters Section */}
                  <div className="mb-1"> {/* Reduced the margin-bottom for tighter spacing */}
                    <label className="block mb-1"><strong>Filters</strong></label> {/* Reduced margin-bottom for the title */}

                    {/* Company Filters */}
                    <div className="flex flex-wrap items-center mb-1"> {/* Reduced mb for smaller gap between rows */}
                      <div className="flex items-center space-x-2">
                        <label><strong>Companies: </strong></label>
                        <CheckboxGroup
                          inline
                          name="company-filter-group"
                          value={companiesSelected}
                          onChange={handleCompanyFilterChange}
                        >
                          {COMPANY_FILTER_LIST.map((company) => (
                            <Checkbox key={company.value} value={company.value}>
                              {company.name}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </div>
                    </div>
                        
                    {/* Filters Section */}
                    <div className="flex flex-wrap gap-4 items-center">
                      {/* Filter By */}
                      <div className="flex items-center space-x-2">
                        <label><strong>Ad Status: </strong></label>
                        <CheckboxGroup
                          inline
                          name="active-filter-group"
                          value={activeFiltersSelected}
                          onChange={handleActiveFilterChange}
                        >
                          {ACTIVE_FILTER_LIST.map((filter) => (
                            <Checkbox key={filter.value} value={filter.value}>
                              {filter.name}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </div>
                        
                      {/* Platforms */}
                      <div className="flex items-center space-x-2">
                        <label><strong>Platforms: </strong></label>
                        <CheckboxGroup
                          inline
                          name="platform-filter-group"
                          value={platformsSelected}
                          onChange={handlePlatformChange}
                        >
                          {PLATFORMS_FILTER_LIST.map((platform) => (
                            <Checkbox key={platform.value} value={platform.value}>
                              {platform.name}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </div>
                        
                      {/* Asset Type */}
                      <div className="flex items-center space-x-2">
                        <label><strong>Asset Type: </strong></label>
                        <CheckboxGroup
                          inline
                          name="asset-type-filter-group"
                          value={assetTypesSelected}
                          onChange={handleAssetTypeFilterChange}
                        >
                          {ASSET_TYPE_FILTER_LIST.map((assetType) => (
                            <Checkbox key={assetType.value} value={assetType.value}>
                              {assetType.name}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </div>
                    </div>
                  </div>
                        
                        

                  {/* Ad Cards */}
                  <div className="flex flex-col min-h-screen">
                    <AdCards
                      ads={allAds}
                      dateRange={dateRange}
                      activeFilterSelected={activeFiltersSelected}
                      sortFilterSelected={sortSelected}
                      platformFilterSelected={platformsSelected}
                      assetTypeFilterSelected={assetTypesSelected}
                      companiesFilterSelected={companiesSelected}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AdsPage;
